import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import styled from "styled-components"

import { graphql, Link } from "gatsby"

import HeaderImage from "../../components/Headers/HeaderImage_right--overlay"
import { stringify } from "qs"
import { serialize } from "dom-form-serializer"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  StyledInput,
  StyledTextArea,
  StyledButton,
} from "../../components/SharedStylings/ContactForm"
import {deCH} from "../../lang/de-CH"

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  margin: 1rem 0;
  justify-content: center;
  align-items: center;

  @media (min-width: 801px) {
    padding: 1rem 5vw;
  }

  @media (min-width: 992px) {
    padding: 1rem 10vw;
  }
`
// Form styling
const Formular = styled.div`
  width: 95%;
  padding: 1rem 0.5rem;

  @media (min-width: 801px) {
    width: 50%;
  }
`
const Message = styled.p`
  text-align: center;
`
const StyledHeader = styled.h2`
  font-weight: bold;

  font-size: 3rem;
  color: var(--secondary-medium);
`
const StyledSectionHeader = styled.h1`
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-weight: bold;
  color: var(--dark-grey);
`
const StyledBody = styled.p`
  font-size: 1.25rem;
  color: var(--dark-grey);
`
const StyledCard = styled.div`
  padding-top: 2rem;

  width: 90%;
  margin: 0.5rem 0;

  @media (min-width: 801px) {
    margin: 0 2rem;
    width: 20rem;
  }
`
const WWNBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 801px) {
    flex-direction: row;
  }
`
const WWNTextBox = styled.div`
  width: 100%;
  margin: 0 2rem;

  @media (min-width: 801px) {
    width: 50%;
  }
`
const WWNImageWrapper = styled.div`
  display: flex;

  @media (min-width: 801px) {
    width: 25%;
    align-items: center;
    justify-content: center;
  }
`
const StyledSectionblue = styled(StyledSection)`
  background-color: var(--secondary20);
`
const RowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 1rem;
`
const FactCard = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: white;
  padding: 1rem;
  border-radius: 4px;

  width: 90%;
  margin: 0.5rem 0;

  :hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  @media (min-width: 801px) {
    width: 20rem;
    margin: 1rem;
  }
`
const FactsHeader = styled(StyledHeader)`
  min-width: 5vw;
  font-size: 3rem;
  color: var(--dark-grey);
`
const StyledLink = styled.a`
  font-size: 0.75rem;
`

class ContactFormPage extends React.Component {
  static defaultProps = {
    name: "AerzteChFR",
    subject: "Aerztekontakt CH FR", // optional subject of the notification email
    action: "",
    successMessage: deCH.Physicians.FormThankYou,
    errorMessage: deCH.Physicians.FormError,
  }

  state = {
    alert: "",
    disabled: false,
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.disabled) return

    const form = e.target
    const data = serialize(form)
    this.setState({ disabled: true })
    fetch(form.action + "?" + stringify(data), {
      method: "POST",
    })
      .then(res => {
        if (res.ok) {
          return res
        } else {
          throw new Error("Network error")
        }
      })
      .then(() => {
        form.reset()
        this.setState({
          alert: this.props.successMessage,
          disabled: false,
        })
      })
      .catch(err => {
        console.error(err)
        this.setState({
          disabled: false,
          alert: this.props.errorMessage,
        })
      })
  }

  render() {
    let txt = deCH.Physicians;

    const { name, subject, action } = this.props
    const Header = {
      label: "HeaderImage",
      AriaLabel: "HeaderImage",
      title: txt.Title,
      small: this.props.data.HeaderImage_small.childImageSharp.fluid,
      medium: this.props.data.HeaderImage_medium.childImageSharp.fluid,
      large: this.props.data.HeaderImage_large.childImageSharp.fluid,
      alt: "Apotheker",
      headerColor: "black",
      textLocationY: "center",
      textLocationX: "center",
    }

    return (
      <Layout location="CH" language="de-CH">
        <Seo
          title="DoryGo - Ihre PatientInnen in besten Händen"
          description="Informationen für Ärzte"
          lang="de-CH"
        />

        <HeaderImage props={Header} />

        <StyledSection>
          <StyledSectionHeader>{txt.HeaderInShort}</StyledSectionHeader>
          <RowWrapper>
            <StyledCard>
              <GatsbyImage
                image={this.props.data.Delivery.childImageSharp.gatsbyImageData}
                alt="Lieferdienst"
              />
              <StyledBody>{txt.BodyDelivery}
              </StyledBody>
            </StyledCard>
            <StyledCard>
              <GatsbyImage
                image={this.props.data.Blister.childImageSharp.gatsbyImageData}
                alt="Lieferdienst"
              />
              <StyledBody>{txt.BodyPresort}</StyledBody>
            </StyledCard>
            <StyledCard>
              <GatsbyImage
                image={this.props.data.App.childImageSharp.gatsbyImageData}
                alt="Lieferdienst"
              />
              <StyledBody>{txt.BodyReminder}</StyledBody>
            </StyledCard>
          </RowWrapper>
        </StyledSection>

        <StyledSection>
        <StyledSectionHeader dangerouslySetInnerHTML={{ __html: txt.HeaderWeNeed }}></StyledSectionHeader>
          <WWNBox>
            <WWNImageWrapper>
              <GatsbyImage
                image={
                  this.props.data.Prescription.childImageSharp.gatsbyImageData
                }
                alt="Lieferdienst"
              />
            </WWNImageWrapper>

            <WWNTextBox>
              <StyledBody>{txt.BodyCoveredByHI}</StyledBody>
            </WWNTextBox>
          </WWNBox>
        </StyledSection>

        <StyledSectionblue>
          <FactsHeader>{txt.HeaderKF}</FactsHeader>
          <RowWrapper>
            <FactCard>
              <StyledHeader>{txt.Header50pc}</StyledHeader>
              <StyledBody>{txt.Body50pc}{" "}<StyledLink
                  href="https://www.who.int/chp/knowledge/publications/adherence_report/en/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  {txt.TxtSourceWHO}  {" "}
                </StyledLink>
              </StyledBody>
            </FactCard>
            <FactCard>
              <StyledHeader>{txt.Header200k}</StyledHeader>
              <StyledBody>
                  {txt.Body200k}{" "}
                <StyledLink
                  href="https://www.oecd.org/officialdocuments/publicdisplaydocumentpdf/?cote=DELSA/HEA/WD/HWP(2018)2&docLanguage=En"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}{txt.TxtSourceOECD}{" "}
                </StyledLink>
              </StyledBody>
            </FactCard>
            <FactCard>
              <StyledHeader>{txt.Header125G}</StyledHeader>
              <StyledBody>{txt.Body125G}{" "}
                <StyledLink
                  href="https://www.oecd.org/officialdocuments/publicdisplaydocumentpdf/?cote=DELSA/HEA/WD/HWP(2018)2&docLanguage=En"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}{txt.TxtSourceOECD}{" "}
                </StyledLink>
              </StyledBody>
            </FactCard>
          </RowWrapper>
        </StyledSectionblue>

        <StyledSection>
          <Formular>
            <StyledSectionHeader>{txt.TxtAnyQuestion}</StyledSectionHeader>
            <p>{" "}{txt.TxtUseForm}</p>
            <form
              name={name}
              action={action}
              data-netlify="true"
              onSubmit={this.handleSubmit}
            >
              <input type="hidden" name="form-name" value={name} />
              <StyledInput
                type="text"
                name="Name"
                placeholder="Name*"
                required
              />{" "}
              <StyledInput
                type="email"
                name="Email"
                placeholder="E-Mail*"
                required
              />
              <StyledInput
                type="tel"
                name="phone"
                placeholder="Telefonnummer"
              />
              <StyledTextArea
                id="message"
                name="Message"
                rows="4"
                cols="50"
                placeholder="Nachricht*"
                required
              />{" "}
              <br />
              <p>{txt.TxtPlichtsFeld}<br />
              {txt.TxtDSV1}{" "}
                <Link to="/datenschutzvereinbarung">
                  {" "}
                  {txt.TxtDSV2}{" "}
                </Link>{" "}
              </p>{" "}
              <br />
              {!!subject && (
                <input type="hidden" name="subject" value={subject} />
              )}
              {this.state.alert && <Message>{this.state.alert}</Message>}
              <StyledButton type="submit" disabled={this.state.disabled}>
                {txt.BtnSend}
              </StyledButton>
            </form>
          </Formular>
        </StyledSection>
      </Layout>
    )
  }
}

export default ContactFormPage

export const query = graphql`
  query {
    HeaderImage_large: file(
      relativePath: { eq: "All-in-one_large.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HeaderImage_medium: file(
      relativePath: { eq: "All-in-one_large.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HeaderImage_small: file(
      relativePath: { eq: "All-in-one_small.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 490) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Blister: file(relativePath: { eq: "Blister.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          height: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    Delivery: file(relativePath: { eq: "Mail delivery.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          height: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    App: file(relativePath: { eq: "Rezept anfordern 4.png" }) {
      childImageSharp {
        gatsbyImageData(
          height: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    Prescription: file(relativePath: { eq: "prescription_final.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 350
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
