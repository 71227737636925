import styled from "styled-components"
import { Link } from "gatsby"

export const Cta = styled.button`
  margin-bottom: 0.5rem;
  background-color: var(--primary-low);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 12px 24px;
  border-style: none;
  color: ${props => props.buttonColor || "black"};
  font-size: 1rem;
  border-radius: 8px;

  &:hover {
    background-color: var(--primary-medium);
    border-color: var(--primary-high);
    box-shadow: none;
  }

  &:active {
    background-color: var(--primary-high);
    border-color: var(--primary-high);
  }


  @media (min-width: 768px) {
    font-size: 1.25rem;
  }

  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
`

export const SecondaryCta = styled(Cta)`
background-color: var(--secondary-high);
color:white;
margin: 1rem 0;
&:hover {
    background-color: var(--secondary-medium);
    border-color: var(--secondary-high);
    box-shadow: none;
  }

  &:active {
    background-color: var(--secondary-high);
    border-color: var(--secondary-high);
  }


`


export const CTAwide = styled.button`
  margin-bottom: 0.5rem;
  background-color: var(--primary-low);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 12px 32px;
  border-style: none;
  border-radius: 8px;
  color: ${props => props.buttonColor || "black"};
  font-size: 1.5rem;

  &:hover {
    background-color: var(--primary-medium);
    border-color: var(--primary-high);
    box-shadow: none;
  }

  &:active {
    background-color: var(--primary-high);
    border-color: var(--primary-high);
  }
`
export const OutlinedButton = styled.button`
  margin-bottom: 0.5rem;
  margin-top: 2rem;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.buttonColor || "var(--dark-grey)"};
  color: ${props => props.buttonColor || "black"};
  font-size: 1rem;
  padding: 8px 16px;
  border-radius: 8px;

  &:hover {
    background-color: var(--primary-medium);
    border-color: var(--primary-medium);
  }

  @media (max-width: 768px) {
    margin: 1rem 0;
  }
`


export const StyledSectionHeader = styled.h2`
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin:0;
  margin-bottom:1rem;
  @media (max-width: 768px) {
    font-size: 1.8rem;
    padding-bottom: 0;
    padding-top: 0.5rem;
  }
`

export const StyledSubHeader = styled.h3``

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.backgroundColor || "white"};
  padding: 0 5vw;
  margin: 1rem 0;
  border-radius: 4px;
  min-height: 60vh;

  @media (max-width: 768px) {
    margin: 1rem 1rem;
    padding: 0;
  }
`


export const StyledLink = styled.a`

color: var(--secondary80);
font-size: 1rem;

@media (min-width: 768px) {
  font-size: 1.25rem;
}
`

export const SBButtonPrimary = styled.button`
border: none;
font-family: 'Montserrat';
font-weight: 700;
font-size: 16px;
line-height: 1.5;
padding: 16px 21px;
margin-top: 45px!important;
margin-bottom: 15px!important;
color: #fff;
border-radius: 50px;
background-color: #DAE3EA;
`

export const SBButtonPrimaryOutlined = styled.button`
font-family: 'Montserrat';
font-weight: 700;
font-size: 16px;
line-height: 1.5;
padding: 16px 70px;
margin-bottom: 42px!important;
color: #59C6F2;
border-radius: 50px;
background-color: #fff;
border-color: #59C6F2!important;
border-width: 2px;
border-style: solid;
`

export const LinkButton = styled.button`
border: none;
font-weight: 400;
font-size 18px;
font-family: 'Montserrat';
color: #59C6F2;
background-color: #fff
`

export const GTMButtonPrimary = styled.button`
  background-color: var(--secondary-medium);
  border: none;
  color: white;
  border-radius: 5px;
  text-transform: uppercase;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;

  &:hover {
    background-color: var(--secondary-high);
    border-color: var(--secondary-high);
    box-shadow: none;
  }

  &:active {
    background-color: var(--secondary-low);
    border-color: var(--secondary-high);
  }
  &:disabled {
    background-color: var(--light-grey);
    border-color: var(--secondary-high);
  }
`

export const GTMButtonSecondary = styled.button`
  background-color: white;
  border-color: var(--secondary-medium)
  border: none;
  border-radius: 5px;
  color: var(--secondary-medium);
  text-transform: uppercase;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;

  &:hover {
    background-color: var(--secondary-verylow);
    border-color: var(--secondary-medium);
    box-shadow: none;
  }

  &:active {
    background-color: var(--secondary-high);
    border-color: var(--secondary-high);
  }
  &:disabled {
    background-color: var(--light-grey);
    color: white;
    border: none;
  }
`
