import styled from "styled-components"
import { Cta } from "../SharedStyledComponents"

export const StyledInput = styled.input`
  margin: 0.25rem 0;
  width: 100%;
  padding: 0.125rem 0.5rem;
  height: 2rem;
`

export const StyledTextArea = styled.textarea`
  margin: 0.25rem 0;
  width: 100%;
  padding: 0.125rem 0.5rem;

`

export  const StyledButton = styled(Cta)`
  width: 100%;
  padding: 0.5rem 0.5rem;
  margin: 0;
`