import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { Cta } from "../GlobalStyledComponents"
import { Link } from "gatsby"
/**
 * In this functional component a fullscreen <BackgroundImage /> may be created
 * with art-directed images.
 * @param className   string    className(s) from styled-components.
 * @param children    nodes     Child-components.
 * @return {*}
 * @constructor
 */

const HeaderImage = ({ className, props }) => {
  const backgroundArtDirectionStack = [
    props.small,
    {
      ...props.medium,
      media: `(min-width: 491px)`,
    },
    {
      ...props.large,
      media: `(min-width: 1401px)`,
    },
  ]

  return (
    <>

        <StyledBackgroundImage
          Tag="section"
          className={className}
          title={props.label}
          fluid={backgroundArtDirectionStack}
          backgroundColor={`#040e18`}
          id="adfullscreenbg"
          role="img"
          aria-label={props.AriaLabel}
          preserveStackingContext={true}
          textLocationY={props.textLocationY}
          textLocationX={props.textLocationX}
        >
          <TextWrapper padding={props.padding}>
            <StyledHeader headerColor={props.headerColor}>
              {props.title}
            </StyledHeader>
            {props.subtext && (
              <StyledSubText subheaderColor={props.subheaderColor}>
                {props.subtext}{" "}
              </StyledSubText>
            )}
            {props.LinkTarget && (
        <ButtonWrapper>
          <Link to={props.LinkTarget}>
            <Cta buttonColor={props.buttonColor}>
              {" "}
              {props.LinkText}
            </Cta>
          </Link>
        </ButtonWrapper>
      )}
          </TextWrapper>
        </StyledBackgroundImage>
    </>
  )
}


export const ButtonWrapper = styled.div`
  text-align: center;
  margin: 1.5rem 0;
  align-self: center;

  @media (min-width: 579px) {
  align-self:flex-start;
  }
`

const StyledBackgroundImage = styled(BackgroundImage)`
  justify-content: ${props => props.textLocationX || "flex-start"};
  align-items:${props => props.textLocationY || "flex-start"};
  display: flex;

  @media (min-width: 579px) {
    align-items: center;
    justify-content: flex-start;
  align-items: center;
  }
`

const StyledHeaderImage = styled(HeaderImage)`
  width: 100%;
  height: 450px;
  display: flex;

  background-position: bottom right;

  @media (min-width: 768px) {
    height:  400px;
  }

  @media (min-width: 992px) {
    height:  600px;
  }
`
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding:1.5rem;
  margin: 0 2rem 1rem;
  background-color:rgb(255,255,255,0.5);


  @media (min-width: 577px) {
    background-color:transparent;
    padding:0;
    margin: 0;
    padding-left: 5vw;
    width: 50%;
    justify-content: center;
  }

  @media (min-width: 992px) {
    margin: 0;
    padding-left: ${props => props.padding || "5vw"};
    width: 50%;
  }
`

const StyledSubText = styled.p`
  color: ${props => props.subheaderColor || "black"};
  text-align: left;
  margin-top: 0.2rem;
  font-size: 1.25rem;


  margin-bottom: 2.5rem;
  width: 100%;

  @media (min-width: 992px) {
    font-size: 1.75rem;
    width: 50%;
  }
`

const StyledHeader = styled.h1`
  flex-direction: column;
  color: ${props => props.headerColor || "black"};
  text-align: left;
  font-size: 2.5rem;
  font-weight: bold;
  opacity:100%;

  @media (min-width: 992px) {
    font-size: 3rem;
  }
`



export default StyledHeaderImage
